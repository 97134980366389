import React from "react";
import { useTranslation } from "react-i18next";
import withTheme from "@material-ui/core/styles/withTheme";
import TestHeader from "../TestHeader";
import { VideoBandwidthIco, VideoQualityIco } from "../../icons";
import { RenderValues } from "../helpers/utils";
import { IColorThresholds, TestConfiguration } from "twillio-tests/core/testConfiguration";
import { formatNumber, getColorValue } from "../../../helpers/utils";
import { ITestsResult, IVideoBandwidth } from "twillio-tests/core/TestResults";
import { Typography, withStyles } from "@material-ui/core";
import ResultRow from "./UI/ResultRow";

interface IVideoBandwidthTestProps {
  videoQualityMode?: boolean;
  mainColor?: string;
  testResult?: ITestsResult;
  theme?: any;
  colorThresholds?: IColorThresholds;
  knowledgeUrl?: string;
  estimateTime?: number;
  testName?: string;
  testTitle?: string;
  config?: TestConfiguration;
  classes: any;
}

const initialValues: IVideoBandwidth = {
  bandwidthEstimate: undefined,
  bitrate: undefined,
  jitter: undefined,
  roundTripTime: undefined,
  fractionLoss: undefined,
};

const styles = (theme: any): any => ({
  testContainer: {
    display: "flex",
    flex: 1,
    gap: "52px",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px",
  },
  estimatedTimeContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: "4px",
    alignItems: "center",
    justifyCenter: "center",
  },
  resultContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    minWidth: "167px",
    "& > :not(:first-child)": {
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderTop: `1px solid ${theme.newPalette.other.divider}`,
    },
  },
  resultSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  titleColor: {
    color: theme.newPalette.text.primary,
    textTransform: "capitalize",
  },
  variantDisplay: {
    fontSize: "38px",
    fontWeight: 700,
    lineHeight: 1.5,
    letterSpacing: "-0.5px",
  },
});

const VideoBandwidthTest = (props: IVideoBandwidthTestProps) => {
  const {
    mainColor,
    testResult,
    theme,
    knowledgeUrl,
    estimateTime,
    colorThresholds,
    videoQualityMode,
    testName,
    testTitle,
    config,
    classes,
  } = props;

  const getTestName = (): string => {
    if (videoQualityMode) {
      return "videoQualityTest";
    } else if (testName && testName === "VideoBandwidth2") {
      return "videoBandwidthTest2";
    } else {
      return "videoBandwidthTest";
    }
  };

  const videoBandwidthTest = (testResult && testResult[getTestName()]) || initialValues;
  const currentThresholds: any = videoQualityMode
    ? colorThresholds?.VideoQuality
    : colorThresholds?.VideoBandwidth;

  const { bandwidthEstimate, jitter, roundTripTime, fractionLoss, error } = videoBandwidthTest;

  const defaultProps = {
    mainColor,
    color: config?.useNewWidgetDesign
      ? theme.newPalette.text.primary
      : theme.customColors.successColor,
    formatting: formatNumber,
  };

  const colorTypes = {
    errorColor: config?.useNewWidgetDesign
      ? theme.newPalette.error.main
      : theme.customColors.errorColor,
    warningColor: config?.useNewWidgetDesign
      ? theme.newPalette.warning.main
      : theme.customColors.warningColor,
    successColor: config?.useNewWidgetDesign
      ? theme.newPalette.text.primary
      : theme.customColors.successColor,
  };

  const bitrate = isNaN(Number(videoBandwidthTest.bitrate))
    ? undefined
    : videoBandwidthTest.bitrate;

  const formatBandwidthEstimate = (value: number | string) => {
    if (!bitrate) {
      return `${formatNumber(Number(value))} <span>kbps</span>`;
    } else if (!value) {
      return `${formatNumber(Number(bitrate))} <span>kbps</span>`;
    } else {
      return `<div class='bandwidth-estimate-row'>
        <div>${formatNumber(Number(value))}</div>
        <div>
          <span>bitrate</span>
          ${formatNumber(Number(bitrate))}
          <span>kbps</span>
        </div>
      </div>`;
    }
  };

  const getTestTitle = (): string => {
    if (videoQualityMode) {
      return t("tests:video.quality");
    } else if (testTitle) {
      return t(testTitle);
    } else {
      return t("tests:video:bandwidth");
    }
  };

  const { t } = useTranslation(["common", "tests"]);
  const title = getTestTitle();
  return (
    <TestHeader
      icon={videoQualityMode ? <VideoQualityIco /> : <VideoBandwidthIco />}
      title={title}
      tooltip={title}
      error={error}
      knowledgeUrl={knowledgeUrl}
      estimateTime={estimateTime}
      testName={testName}
      badge={testResult?.testCall?.transportProtocol}
    >
      {config?.useNewWidgetDesign ? (
        <div className={classes.testContainer}>
          <div className={classes.estimatedTimeContainer}>
            <Typography variant="body1" className={classes.titleColor}>
              {t("estimated")}
            </Typography>
            <div className={classes.resultSection}>
              <Typography
                className={classes.variantDisplay}
                style={{
                  color: currentThresholds?.bandwidthEstimate
                    ? getColorValue(
                        {
                          ...colorTypes,
                          value: bandwidthEstimate,
                          warningValue: currentThresholds.bandwidthEstimate.warning,
                          errorValue: currentThresholds.bandwidthEstimate.error,
                        },
                        currentThresholds.bandwidthEstimate.isLess
                      )
                    : defaultProps.color,
                }}
              >
                {formatNumber(Number(bandwidthEstimate))}
              </Typography>
              <Typography variant="caption">kbps</Typography>
            </div>
          </div>
          <div className={classes.resultContainer}>
            <ResultRow
              value={Number(jitter).toFixed(0)}
              label={t("jitter")}
              unit="ms"
              color={
                currentThresholds?.jitter
                  ? getColorValue(
                      {
                        ...colorTypes,
                        value: jitter,
                        warningValue: currentThresholds.jitter.Rwarning,
                        errorValue: currentThresholds.jitter.error,
                      },
                      currentThresholds.roundTripTime.isLess
                    )
                  : defaultProps.color
              }
            />
            <ResultRow
              value={formatNumber(Number(roundTripTime))}
              label={t("Round Trip")}
              unit="ms"
              color={
                currentThresholds?.roundTripTime
                  ? getColorValue(
                      {
                        ...colorTypes,
                        value: roundTripTime,
                        warningValue: currentThresholds.roundTripTime.warning,
                        errorValue: currentThresholds.roundTripTime.error,
                      },
                      currentThresholds.roundTripTime.isLess
                    )
                  : defaultProps.color
              }
            />
            <ResultRow
              value={formatNumber(Number(fractionLoss))}
              label={t("Packet Loss")}
              unit="%"
              color={
                currentThresholds?.fractionLoss
                  ? getColorValue(
                      {
                        ...colorTypes,
                        value: fractionLoss,
                        warningValue: currentThresholds.fractionLoss.warning,
                        errorValue: currentThresholds.fractionLoss.error,
                      },
                      currentThresholds.fractionLoss.isLess
                    )
                  : defaultProps.color
              }
            />
          </div>
        </div>
      ) : (
        <div className="tests-card-content tests-card-content--min tst-video-bandwidth">
          <table>
            <tbody>
              {!videoQualityMode && (
                <tr className="tst-bandwidthEstimate" id="vb-r-estimated">
                  <td>{t("estimated")}</td>
                  <td id="vb-c-estimated">
                    <RenderValues
                      {...defaultProps}
                      value={bandwidthEstimate}
                      formatting={formatBandwidthEstimate}
                      color={
                        currentThresholds?.bandwidthEstimate
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: bandwidthEstimate,
                                warningValue: currentThresholds.bandwidthEstimate.warning,
                                errorValue: currentThresholds.bandwidthEstimate.error,
                              },
                              currentThresholds.bandwidthEstimate.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </td>
                </tr>
              )}
              {videoQualityMode && (
                <tr className="tst-bandwidthEstimate" id="vb-r-bitrate">
                  <td>{t("bitrate")}</td>
                  <td id="vb-c-bitrate">
                    <RenderValues
                      {...defaultProps}
                      value={bitrate}
                      formatting={(value: string | number) =>
                        `${formatNumber(Number(value))} <span>kbps</span>`
                      }
                      color={
                        currentThresholds?.bitrate
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: bitrate,
                                warningValue: currentThresholds.bitrate.warning,
                                errorValue: currentThresholds.bitrate.error,
                              },
                              currentThresholds.bitrate.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </td>
                </tr>
              )}
              {!videoQualityMode && (
                <tr className="tst-jitter" id="vb-r-jitter">
                  <td>{t("jitter")}</td>
                  <td id="vb-c-jitter">
                    <RenderValues
                      {...defaultProps}
                      formatting={(value: string | number) =>
                        `${Number(value).toFixed(0)} <span>ms</span>`
                      }
                      value={jitter}
                      color={
                        currentThresholds?.jitter
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: jitter,
                                warningValue: currentThresholds.jitter.Rwarning,
                                errorValue: currentThresholds.jitter.error,
                              },
                              currentThresholds.roundTripTime.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </td>
                </tr>
              )}
              {!videoQualityMode && (
                <tr className="tst-roundTripTime" id="vb-r-roundTrip">
                  <td>{t("Round Trip")}</td>
                  <td id="vb-c-roundTrip">
                    <RenderValues
                      {...defaultProps}
                      value={roundTripTime}
                      formatting={(value: string | number) =>
                        `${formatNumber(Number(value))} <span>ms</span>`
                      }
                      color={
                        currentThresholds?.roundTripTime
                          ? getColorValue(
                              {
                                ...colorTypes,
                                value: roundTripTime,
                                warningValue: currentThresholds.roundTripTime.warning,
                                errorValue: currentThresholds.roundTripTime.error,
                              },
                              currentThresholds.roundTripTime.isLess
                            )
                          : defaultProps.color
                      }
                    />
                  </td>
                </tr>
              )}
              <tr className="tst-fractionLoss" id="vb-r-packetLoss">
                <td>{t("Packet Loss")}</td>
                <td id="vb-c-packetLoss">
                  <RenderValues
                    {...defaultProps}
                    value={fractionLoss}
                    formatting={(value: string | number) =>
                      `${formatNumber(Number(value))} <span>%</span>`
                    }
                    color={
                      currentThresholds?.fractionLoss
                        ? getColorValue(
                            {
                              ...colorTypes,
                              value: fractionLoss,
                              warningValue: currentThresholds.fractionLoss.warning,
                              errorValue: currentThresholds.fractionLoss.error,
                            },
                            currentThresholds.fractionLoss.isLess
                          )
                        : defaultProps.color
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </TestHeader>
  );
};

export default withTheme()(withStyles(styles)(VideoBandwidthTest));
