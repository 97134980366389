import React from "react";

interface DonutChartProps {
  value: number;
  small?: boolean;
  status?: string;
  parentId?: string;
  childId?: string;
  textColor?: string;
}

const DonutChart = ({
  value,
  small = false,
  status,
  parentId,
  childId,
  textColor,
}: DonutChartProps) => {
  const max = 5;
  const strokeWidth = small ? 6 : 12;
  const circleSize = small ? 71 : 168;
  const radius = circleSize / 2;
  const svgSize = circleSize + strokeWidth * 2;
  const circumference = 2 * Math.PI * radius;
  const isValueNumber = !isNaN(parseFloat(value.toString())) && isFinite(value as any);
  const adjustedValue = isValueNumber ? (Number(value) / max) * 100 : 0;
  const offset = ((100 - adjustedValue) / 100) * circumference;

  return (
    <svg width={svgSize} height={svgSize} viewBox={`0 0 ${svgSize} ${svgSize}`}>
      <circle
        fill="transparent"
        stroke="#24354414"
        strokeWidth={strokeWidth}
        r={radius}
        cx={svgSize / 2}
        cy={svgSize / 2}
      />
      <circle
        fill="transparent"
        stroke={textColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        r={radius}
        cx={svgSize / 2}
        cy={svgSize / 2}
        transform={`rotate(-90 ${svgSize / 2} ${svgSize / 2})`}
      />
      <text
        x={svgSize / 2}
        y={svgSize / 2}
        textAnchor="middle"
        fill="#000000"
        dy={status ? "-1.3em" : "-0.3em"}
        style={{
          fontSize: small ? "12px" : "16px",
          fontFamily: "Roboto",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: small ? "20px" : "24px",
          letterSpacing: "0.15px",
        }}
        id={parentId}
      >
        MOS
      </text>
      {isValueNumber ? (
        <text
          x={svgSize / 2}
          y={svgSize / 2}
          textAnchor="middle"
          fill={textColor}
          dy={status ? "0.5em" : ".9em"}
          style={{
            fontSize: small ? "16px" : "30px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: small ? "20px" : "37.5",
            textAlign: "center",
            letterSpacing: "0.15px",
          }}
          id={childId}
        >
          {value}
        </text>
      ) : (
        <text
          x={svgSize / 2}
          y={svgSize / 2}
          textAnchor="middle"
          fill="rgba(211, 47, 47, 1)"
          dy={status ? "0.5em" : ".9em"}
          style={{
            fontSize: small ? "16px" : "30px",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: small ? "20px" : "37.5",
            textAlign: "center",
            letterSpacing: "0.15px",
          }}
          id={childId}
        >
          N/A
        </text>
      )}
      {status && (
        <text
          x={svgSize / 2}
          y={svgSize / 2}
          textAnchor="middle"
          fill="#24354461"
          dy="3em"
          style={{
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px",
            textAlign: "center",
            letterSpacing: "0.4px",
          }}
        >
          {status}
        </text>
      )}
    </svg>
  );
};

export default DonutChart;
