import { FormControl, MenuItem, Select, withStyles, withTheme } from "@material-ui/core";
import React, { useState } from "react";
import { TestConfiguration } from "twillio-tests/core/testConfiguration";
import i18n from "../../i18n";
import { HeaderGlobeIcon } from "../TestsContainer/Tests/NewTests/UI/Icons";
import { ITestsResult } from "twillio-tests/core/TestResults";

interface HeaderProps {
  classes?: any;
  config?: TestConfiguration;
  testResult?: ITestsResult;
}

const styles = (theme: any): any => ({
  wrapper: {
    padding: "16px 0",
    borderBottom: `1px solid ${theme.newPalette.other.divider}`,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 24px",
    maxWidth: 1230,
    margin: "0 auto",
    width: "100%",
  },
  languageInput: {
    fontSize: "15px",
    lineHeight: "26px",
    letterSpacing: "0.46px",
    fontWeight: 500,
    color: theme.newPalette.text.primary,
    textTransform: "uppercase",
  },
  formControl: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    gap: "8px",
  },
});

const Header = ({ classes, config, testResult }: HeaderProps) => {
  const [currentLanguage, setCurrentLanguage] = useState(
    i18n.language || window.localStorage.i18nextLng
  );

  const showLanguageDropdown: boolean =
    config && config.showLanguageSelect ? config.showLanguageSelect : false;

  const supportedLanguages: string[] =
    config && config.supportedLanguages ? config.supportedLanguages : [];
  const current_language: string = i18n.language || window.localStorage.i18nextLng;

  const handleChangeLanguage = (event: React.ChangeEvent<{ value: unknown }>) => {
    const lang: string = event.target.value as string;
    changeLanguage(lang);
  };

  const changeLanguage = (lang: string) => {
    setCurrentLanguage(lang);
    i18n.changeLanguage(lang);
    let currentUrlParams = new URLSearchParams(window.location.search);
    currentUrlParams.set("lang", lang);
    var title: string = document.getElementsByTagName("title")[0].innerHTML;
    window.history.pushState(
      {},
      title,
      window.location.pathname + "?" + currentUrlParams.toString()
    );
  };

  if (
    config &&
    supportedLanguages &&
    supportedLanguages.length &&
    supportedLanguages.indexOf(current_language) === -1
  ) {
    changeLanguage("en");
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <img src="/testRTC-cyara.png" alt="testRTC by Cyara" width={158} />
        {testResult === null && showLanguageDropdown && supportedLanguages.length > 0 && (
          <FormControl className={classes.formControl}>
            <HeaderGlobeIcon />
            <Select
              variant="standard"
              disableUnderline
              value={currentLanguage}
              onChange={handleChangeLanguage}
              className={classes.languageInput}
              renderValue={() => (
                <div className={classes.selectedValue}>
                  {config?.languageTitles[currentLanguage]}
                </div>
              )}
            >
              {supportedLanguages.map((lng: string, key: number) => {
                return (
                  <MenuItem key={key} value={lng}>
                    {config?.languageTitles[lng]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </div>
    </div>
  );
};

export default withTheme()(withStyles(styles)(Header));
