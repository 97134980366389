export const StatusCodes = [
  {
    statusCode: 3001,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428324751759-Status-3001",
  },
  {
    statusCode: 3002,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428330358927-Status-3002",
  },
  {
    statusCode: 4001,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428314594703-Status-4001",
  },
  {
    statusCode: 4002,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428324769807-Status-4002",
  },
  {
    statusCode: 5001,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428346078991-Status-5001",
  },
  {
    statusCode: 6001,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428300782479-Status-6001",
  },
  {
    statusCode: 10001,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428314613775-Status-10001",
  },
  {
    statusCode: 10002,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428330395151-Status-10002",
  },
  {
    statusCode: 20001,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428330400783-Status-20001",
  },
  {
    statusCode: 20002,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428346105103-Status-20002",
  },
  {
    statusCode: 20003,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428300809615-Status-20003",
  },
  {
    statusCode: 20004,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428314639887-Status-20004",
  },
  {
    statusCode: 20005,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428300819983-Status-20005",
  },
  {
    statusCode: 30001,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428314648463-Status-30001",
  },
  {
    statusCode: 30002,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428284948879-Status-30002",
  },
  {
    statusCode: 30003,
    codeLink:
      "https://support.testrtc.com/hc/en-us/articles/8428330429839-Status-30003-30004-30006",
  },
  {
    statusCode: 30004,
    codeLink:
      "https://support.testrtc.com/hc/en-us/articles/8428330429839-Status-30003-30004-30006",
  },
  {
    statusCode: 30006,
    codeLink:
      "https://support.testrtc.com/hc/en-us/articles/8428330429839-Status-30003-30004-30006",
  },
  {
    statusCode: 30005,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428330438159-Status-30005-30007",
  },
  {
    statusCode: 30007,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428330438159-Status-30005-30007",
  },
  {
    statusCode: 40001,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428330440719-Status-40001",
  },
  {
    statusCode: 50001,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428284973583-Status-50001",
  },
  {
    statusCode: 60001,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428330448015-Status-60001",
  },
  {
    statusCode: 60002,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428346144783-Status-60002-60003",
  },
  {
    statusCode: 60003,
    codeLink: "https://support.testrtc.com/hc/en-us/articles/8428346144783-Status-60002-60003",
  },
];
