import { Typography, withStyles } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { formatNumber } from "../../../../helpers/utils";
import { useTranslation } from "react-i18next";

const styles = (theme: any): any => ({
  withTitleContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    paddingBottom: "8px",
    "&:not(:first-child)": {
      paddingTop: "8px",
    },
  },
  titleColor: {
    color: theme.newPalette.text.primary,
    lineHeight: "14.4px",
    textTransform: "capitalize",
  },
  withTitleResultRow: {
    display: "flex",
    justifyContent: "space-between",
    gap: "15px",
    alignItems: "center",
  },
  withTitleSingleRow: {
    display: "flex",
    alignItems: "baseline",
    gap: "4px",
  },
  resultLineContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "8px",
    "&:not(:first-child)": {
      paddingTop: "8px",
    },
  },
  callQualityLine: {
    "&:not(:last-child)": {
      paddingBottom: "8px",
    },
  },
  oneLineContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    paddingBottom: "8px",
    "&:not(:first-child)": {
      paddingTop: "8px",
    },
    "&:not(:last-child)": {
      paddingBottom: "8px",
    },
  },
  resultLabel: {
    color: theme.newPalette.text.primary,
    fontSize: "12px",
    lineHeight: "25px",
    letterSpacing: "0.4px",
    textTransform: "capitalize",
  },
  oneLineLabel: {
    color: theme.newPalette.text.primary,
    fontSize: "12px",
    lineHeight: "14px",
    letterSpacing: "0.15px",
    textTransform: "capitalize",
    width: "82px",
  },
});

function mapStateToProps(state: any) {
  return {
    testResult: state.tests.testResult,
    currentTheme: state.document.theme,
    config: state.tests.config,
  };
}

type Variants = "with-title" | "one-line";

interface ResultRowProps {
  classes: any;
  variant?: Variants;
  title?: string;
  color?: string;
  value?: string | number | null | undefined;
  sessionValue?: string | number | null | undefined;
  label?: string;
  unit?: string;
  callQuality?: boolean;
  size?: string;
}

const ResultRow = ({
  variant,
  classes,
  title,
  color,
  value,
  sessionValue,
  label,
  unit,
  callQuality,
  size,
}: ResultRowProps) => {
  const { t } = useTranslation(["common", "tests"]);

  if (variant === "with-title") {
    return (
      <div className={classes.withTitleContainer}>
        <Typography variant="caption" className={classes.titleColor}>
          {title}
        </Typography>
        <div className={classes.withTitleResultRow}>
          <div className={classes.withTitleSingleRow}>
            <Typography variant="h4" style={{ color: color }}>
              {formatNumber(Number(value))}
            </Typography>
            <Typography variant="caption">kbps</Typography>
          </div>
          <div className={classes.withTitleSingleRow}>
            <Typography variant="h4" style={{ color: color }}>
              {formatNumber(Number(sessionValue))}
            </Typography>
            <Typography variant="caption" className={classes.unitColor}>
              {t("session", { count: 0 })}
            </Typography>
          </div>
        </div>
      </div>
    );
  }

  if (variant === "one-line") {
    return (
      <div className={classes.oneLineContainer}>
        <Typography className={classes.oneLineLabel}>{label}</Typography>
        <div className={classes.withTitleSingleRow}>
          <Typography
            variant={size === "small" ? "h4" : "h3"}
            style={{ color: color }}
            className="line-clamp-1"
          >
            {value}
          </Typography>
          <Typography variant="caption" className={classes.unitColor}>
            {unit}
          </Typography>
        </div>
      </div>
    );
  }
  return (
    <div className={`${classes.resultLineContainer} ${callQuality && classes.callQualityLine}`}>
      <Typography
        className={classes.resultLabel}
        style={{ lineHeight: (size = "small" ? "14.4px" : "25px") }}
      >
        {label}
      </Typography>
      <div className={classes.withTitleSingleRow}>
        <Typography variant="h3" style={{ color: color }}>
          {value && value === "-" ? "-" : value !== "N/A" ? formatNumber(Number(value)) : "N/A"}
        </Typography>
        <Typography variant="caption" className={classes.unitColor}>
          {value !== "N/A" && unit}
        </Typography>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, null)(withStyles(styles)(ResultRow));
