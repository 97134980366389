import React from "react";

export const InfoIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
        fill="#243544"
        fillOpacity="0.54"
      />
    </svg>
  );
};

export const PendingActionsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17 12C14.24 12 12 14.24 12 17C12 19.76 14.24 22 17 22C19.76 22 22 19.76 22 17C22 14.24 19.76 12 17 12ZM18.65 19.35L16.5 17.2V14H17.5V16.79L19.35 18.64L18.65 19.35ZM18 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H6C4.9 3 4 3.9 4 5V20C4 21.1 4.9 22 6 22H12.11C11.52 21.43 11.04 20.75 10.69 20H6V5H8V8H16V5H18V10.08C18.71 10.18 19.38 10.39 20 10.68V5C20 3.9 19.1 3 18 3ZM12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5Z"
        fill="#243544"
        fillOpacity="0.54"
      />
    </svg>
  );
};

export const ArrowDownIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 12V8H11V12H8L12 16L16 12H13Z"
        fill="#243544"
      />
    </svg>
  );
};

export const ArrowUpIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22ZM11 12V16H13V12H16L12 8L8 12H11Z"
        fill="#243544"
      />
    </svg>
  );
};

export const AudioOn = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M28.0013 32.6665C31.8746 32.6665 35.0013 29.5398 35.0013 25.6665V11.6665C35.0013 7.79317 31.8746 4.6665 28.0013 4.6665C24.128 4.6665 21.0013 7.79317 21.0013 11.6665V25.6665C21.0013 29.5398 24.128 32.6665 28.0013 32.6665ZM25.668 11.6665C25.668 10.3832 26.718 9.33317 28.0013 9.33317C29.2846 9.33317 30.3346 10.3832 30.3346 11.6665V25.6665C30.3346 26.9498 29.2846 27.9998 28.0013 27.9998C26.718 27.9998 25.668 26.9498 25.668 25.6665V11.6665ZM39.668 25.6665C39.668 32.1065 34.4413 37.3332 28.0013 37.3332C21.5613 37.3332 16.3346 32.1065 16.3346 25.6665H11.668C11.668 33.9032 17.758 40.6698 25.668 41.8132V48.9998H30.3346V41.8132C38.2446 40.6698 44.3346 33.9032 44.3346 25.6665H39.668Z"
        fill="#243544"
      />
    </svg>
  );
};

export const AudioOff = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.2 11.4332C25.2 9.89317 26.46 8.63317 28 8.63317C29.54 8.63317 30.8 9.89317 30.8 11.4332L30.7767 20.5565L35 24.7332V11.6665C35 7.79317 31.8733 4.6665 28 4.6665C24.4067 4.6665 21.49 7.37317 21.0933 10.8498L25.2 14.9565V11.4332ZM44.3333 25.6665H40.3667C40.3667 27.0198 40.1333 28.3032 39.7367 29.4932L42.7 32.4565C43.7267 30.4032 44.3333 28.0932 44.3333 25.6665ZM10.29 6.67317L7 9.96317L21 23.9632V25.6665C21 29.5398 24.1267 32.6665 28 32.6665C28.5367 32.6665 29.0267 32.5965 29.5167 32.4798L33.39 36.3532C31.7333 37.1232 29.89 37.5665 28 37.5665C21.56 37.5665 15.6333 32.6665 15.6333 25.6665H11.6667C11.6667 33.6232 18.0133 40.2032 25.6667 41.3465V48.9998H30.3333V41.3465C32.4567 41.0432 34.4633 40.2965 36.2833 39.2465L46.0833 49.0465L49.3733 45.7565L10.29 6.67317Z"
        fill="#FF494B"
      />
    </svg>
  );
};

export const VideoOn = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35 18.6667V37.3333H11.6667V18.6667H35ZM37.3333 14H9.33333C8.05 14 7 15.05 7 16.3333V39.6667C7 40.95 8.05 42 9.33333 42H37.3333C38.6167 42 39.6667 40.95 39.6667 39.6667V31.5L49 40.8333V15.1667L39.6667 24.5V16.3333C39.6667 15.05 38.6167 14 37.3333 14Z"
        fill="#243544"
      />
    </svg>
  );
};

export const VideoOff = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.308 18.665L17.6413 13.9984L7.95797 4.33838L4.66797 7.62838L11.038 13.9984H9.33464C8.0513 13.9984 7.0013 15.0484 7.0013 16.3317V39.665C7.0013 40.9484 8.0513 41.9984 9.33464 41.9984H37.3346C37.8246 41.9984 38.2446 41.8117 38.618 41.5784L46.038 48.9984L49.328 45.7084L28.6546 25.035L22.308 18.665ZM11.668 37.3317V18.665H15.7046L34.3713 37.3317H11.668ZM35.0013 18.665V24.755L49.0013 38.755V15.165L39.668 24.4984V16.3317C39.668 15.0484 38.618 13.9984 37.3346 13.9984H24.2446L28.9113 18.665H35.0013Z"
        fill="#FF494B"
      />
    </svg>
  );
};

export const ThumbUpIcon = () => {
  return (
    <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.1237 42.6252H38.2487C39.4591 42.6252 40.4945 41.896 40.932 40.846L45.3362 30.5647C45.4675 30.2293 45.5404 29.8793 45.5404 29.5002V26.5835C45.5404 24.9793 44.2279 23.6668 42.6237 23.6668H33.4216L34.807 17.0022L34.8508 16.5356C34.8508 15.9377 34.6029 15.3835 34.2091 14.9897L32.6633 13.4585L23.0529 23.0689C22.5279 23.5939 22.207 24.3231 22.207 25.1252V39.7085C22.207 41.3127 23.5195 42.6252 25.1237 42.6252ZM25.1237 25.1252L31.4529 18.796L29.4987 26.5835H42.6237V29.5002L38.2487 39.7085H25.1237V25.1252ZM13.457 25.1252H19.2904V42.6252H13.457V25.1252Z"
        fill="#243544"
      />
    </svg>
  );
};

export const ErrorIcon = () => {
  return (
    <svg width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.2784 23.6665L29.5013 27.4436L25.7242 23.6665L23.668 25.7228L27.4451 29.4998L23.668 33.2769L25.7242 35.3332L29.5013 31.5561L33.2784 35.3332L35.3346 33.2769L31.5576 29.4998L35.3346 25.7228L33.2784 23.6665ZM29.5013 14.9165C21.4367 14.9165 14.918 21.4353 14.918 29.4998C14.918 37.5644 21.4367 44.0832 29.5013 44.0832C37.5659 44.0832 44.0846 37.5644 44.0846 29.4998C44.0846 21.4353 37.5659 14.9165 29.5013 14.9165ZM29.5013 41.1665C23.0701 41.1665 17.8346 35.9311 17.8346 29.4998C17.8346 23.0686 23.0701 17.8332 29.5013 17.8332C35.9326 17.8332 41.168 23.0686 41.168 29.4998C41.168 35.9311 35.9326 41.1665 29.5013 41.1665Z"
        fill="#FF494B"
      />
    </svg>
  );
};

export const ErrorStateIcon = () => {
  return (
    <svg
      width="156"
      height="80"
      viewBox="0 0 156 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.91016 40.2883C5.91016 37.7026 7.49751 35.6064 9.4556 35.6064H59.0918C61.0499 35.6064 62.6373 37.7026 62.6373 40.2883C62.6373 42.874 61.0499 44.9701 59.0918 44.9701H9.45559C7.4975 44.9701 5.91016 42.874 5.91016 40.2883Z"
        fill="#F6F7F8"
      />
      <path
        d="M20.0918 55.8937C20.0918 53.308 21.6791 51.2119 23.6372 51.2119H73.2735C75.2316 51.2119 76.8189 53.308 76.8189 55.8937C76.8189 58.4794 75.2315 60.5756 73.2734 60.5756H23.6372C21.6791 60.5756 20.0918 58.4794 20.0918 55.8937Z"
        fill="#F6F7F8"
      />
      <path
        d="M69.7285 40.2883C69.7285 37.7026 71.3159 35.6064 73.274 35.6064H122.91C124.868 35.6064 126.456 37.7026 126.456 40.2883C126.456 42.874 124.868 44.9701 122.91 44.9701H73.274C71.3159 44.9701 69.7285 42.874 69.7285 40.2883Z"
        fill="#F6F7F8"
      />
      <path
        d="M133.545 40.2883C133.545 37.7026 135.132 35.6064 137.09 35.6064H152.454C154.412 35.6064 155.999 37.7026 155.999 40.2883C155.999 42.874 154.412 44.9701 152.454 44.9701H137.09C135.132 44.9701 133.545 42.874 133.545 40.2883Z"
        fill="#F6F7F8"
      />
      <path
        d="M0 55.8937C0 53.308 1.58735 51.2119 3.54544 51.2119H9.45452C11.4126 51.2119 13 53.308 13 55.8937C13 58.4794 11.4126 60.5756 9.45452 60.5756H3.54544C1.58735 60.5756 0 58.4794 0 55.8937Z"
        fill="#F6F7F8"
      />
      <path
        d="M80.3633 24.6818C80.3633 22.0961 81.9506 20 83.9087 20H133.545C135.503 20 137.09 22.0961 137.09 24.6818C137.09 27.2675 135.503 29.3636 133.545 29.3636H83.9087C81.9506 29.3636 80.3633 27.2675 80.3633 24.6818Z"
        fill="#F6F7F8"
      />
      <path
        d="M83.9082 55.8937C83.9082 53.308 85.4956 51.2119 87.4536 51.2119H137.09C139.048 51.2119 140.635 53.308 140.635 55.8937C140.635 58.4794 139.048 60.5756 137.09 60.5756H87.4536C85.4955 60.5756 83.9082 58.4794 83.9082 55.8937Z"
        fill="#F6F7F8"
      />
      <path
        d="M16.5449 24.6818C16.5449 22.0961 18.1323 20 20.0904 20H69.7266C71.6847 20 73.272 22.0961 73.272 24.6818C73.272 27.2675 71.6847 29.3636 69.7266 29.3636H20.0904C18.1323 29.3636 16.5449 27.2675 16.5449 24.6818Z"
        fill="#F6F7F8"
      />
      <path
        d="M118 40C118 62.0914 100.091 80 78 80C55.9086 80 38 62.0914 38 40C38 17.9086 55.9086 0 78 0C100.091 0 118 17.9086 118 40Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78 77C98.4345 77 115 60.4345 115 40C115 19.5655 98.4345 3 78 3C57.5655 3 41 19.5655 41 40C41 60.4345 57.5655 77 78 77ZM78 80C100.091 80 118 62.0914 118 40C118 17.9086 100.091 0 78 0C55.9086 0 38 17.9086 38 40C38 62.0914 55.9086 80 78 80Z"
        fill="#EDEFF0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.3493 17.6316H74.4911C72.1323 17.6316 70.3257 19.7294 70.6756 22.0621L73.9751 44.0587C74.268 46.0115 75.9455 47.4561 77.9202 47.4561C79.8948 47.4561 81.5723 46.0115 81.8653 44.0587L85.1648 22.0621C85.5147 19.7294 83.708 17.6316 81.3493 17.6316ZM74.4911 15C70.5235 15 67.4846 18.5287 68.0731 22.4524L71.3726 44.449C71.8588 47.6901 74.6429 50.0877 77.9202 50.0877C81.1975 50.0877 83.9816 47.6901 84.4677 44.449L87.7672 22.4524C88.3558 18.5287 85.3169 15 81.3493 15H74.4911Z"
        fill="#C6CACE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.9205 62.3686C79.6161 62.3686 80.9907 60.994 80.9907 59.2984C80.9907 57.6028 79.6161 56.2283 77.9205 56.2283C76.2249 56.2283 74.8503 57.6028 74.8503 59.2984C74.8503 60.994 76.2249 62.3686 77.9205 62.3686ZM77.9205 65.0002C81.0695 65.0002 83.6223 62.4474 83.6223 59.2984C83.6223 56.1494 81.0695 53.5967 77.9205 53.5967C74.7715 53.5967 72.2188 56.1494 72.2188 59.2984C72.2188 62.4474 74.7715 65.0002 77.9205 65.0002Z"
        fill="#C6CACE"
      />
    </svg>
  );
};

export const SummarizeIcon = ({ disabled }: { disabled?: boolean }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 3H5C3.9 3 3.01 3.9 3.01 5L3 19C3 20.1 3.89 21 4.99 21H19C20.1 21 21 20.1 21 19V9L15 3ZM5 19V5H14V10H19V19H5ZM9 8C9 8.55 8.55 9 8 9C7.45 9 7 8.55 7 8C7 7.45 7.45 7 8 7C8.55 7 9 7.45 9 8ZM9 12C9 12.55 8.55 13 8 13C7.45 13 7 12.55 7 12C7 11.45 7.45 11 8 11C8.55 11 9 11.45 9 12ZM9 16C9 16.55 8.55 17 8 17C7.45 17 7 16.55 7 16C7 15.45 7.45 15 8 15C8.55 15 9 15.45 9 16Z"
        fill={disabled ? "rgba(36, 53, 68, 0.26)" : "#243544"}
        fillOpacity="0.54"
      />
    </svg>
  );
};

export const HeaderGlobeIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM18.92 8H15.97C15.65 6.75 15.19 5.55 14.59 4.44C16.43 5.07 17.96 6.35 18.92 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4.26 14C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM8.03 8H5.08C6.04 6.34 7.57 5.07 9.41 4.44C8.81 5.55 8.35 6.75 8.03 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM14.34 14H9.66C9.57 13.34 9.5 12.68 9.5 12C9.5 11.32 9.57 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12C14.5 12.68 14.43 13.34 14.34 14ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.36 14C16.44 13.34 16.5 12.68 16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.69 19.9 13.36 19.74 14H16.36Z"
        fill="#243544"
      />
    </svg>
  );
};
